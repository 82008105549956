/**
 * Consistent keys for analytics metadata.
 */
export class AnalyticsMetadataKeys {
    // User
    static readonly userId = "user_id";

    // Content
    static readonly contentTitle = "content_title";
    static readonly payload = "payload";
    static readonly data = "data";
    static readonly timestamp = "timestamp";

    // Events and components
    static readonly triggeredBy = "triggered_by";
    static readonly component = "in_component";
    static readonly componentBaseHtmlType = "component_type";

    // Error reporting
    static readonly internalErrorMessage = "internal_error_message";
    static readonly wrappedError = "wrapped_error";
    static readonly userFacingErrorMessage = "user_facing_error_message";
    static readonly feature = "in_feature";
    static readonly module = "in_module";

    // Logging
    static readonly environment = "running_in_environment";

    // Map
    static readonly zoom = "zoom";
    static readonly minLatitude = "min_latitude";
    static readonly maxLatitude = "max_latitude";
    static readonly minLongitude = "min_longitude";
    static readonly maxLongitude = "max_longitude";

    static readonly geographicLocation = "geographic_location";

    // Booking
    static readonly bookingId = "booking_id";
    static readonly bookingStatus = "booking_status";
    static readonly locationName = "location_name";
    static readonly resourceName = "resource_name";
    static readonly resourceType = "resource_type";
    static readonly durationMinutes = "duration_minutes";
    static readonly startTime = "start_time";
    static readonly endTime = "end_time";
    static readonly latitude = "latitude";
    static readonly longitude = "longitude";
    static readonly price = "price";
    static readonly fee = "fee";
    static readonly tax = "tax";
    static readonly totalPrice = "value";
    static readonly currency = "currency";
    static readonly voucher = "coupon";
    static readonly items = "items";

    // Search
    static readonly query = "query";
    static readonly capacity = "capacity";
    static readonly sort = "sort";

    // List
    static readonly listId = "item_list_id";
    static readonly listName = "item_list_name";
    static readonly listType = "list_type";
    static readonly listLength = "item_list_length";
    static readonly listItemPosition = "index";
    static readonly listItemId = "item_id";
    static readonly listItemName = "item_name";

    // Specific events
    static readonly idleBrokenByEvent = "idle_broken_by";

    private constructor() {}
}
